export const userSettingKeys = {
  experttool: {
    cuTable: 'EXPERTTOOL_CONTROL_UNIT_TABLE',
    fwResultTable: 'EXPERTTOOL_FW_CALCULATION_RESULT_TABLE',
    vehicleCodesTable: 'EXPERTTOOL_VEHICLE_CODES_TABLE',
    expToolFlashForm: 'EXPERTTOOL_FLASH_FORM',
    logHistoryTable: 'EXPERTTOOL_LOG_HISTORY_TABLE',
  } as const,

  vorab: {
    vorabTable: 'VORAB_TABLE',
  } as const,
  serien: {
    serienBdpTable: 'SERIEN_BDP_TABLE',
    inSerieActivationTable: 'IN_SERIE_ACTIVATION_TABLE',
    serienUploadListTable: 'SERIEN_UPLOAD_LIST_TABLE',
  } as const,

  fsflogs: {
    fsfHistoryTable: 'FSF_LOG_HISTORY_TABLE'
  } as const,
};
