import {Component, OnDestroy, OnInit} from '@angular/core';
import {TestautomationService} from "@core/services/testautomation.service";
import {TestautomationTest} from "@shared/models/testautomation/TestautomationTest";
import {map, takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {HttpResponse} from "@angular/common/http";

@Component({
  selector: 'app-fcsc-admin-testautomation',
  templateUrl: './fcsc-admin-testautomation.component.html',
  styleUrls: ['./fcsc-admin-testautomation.component.scss']
})
export class FcscAdminTestautomationComponent implements OnInit, OnDestroy {
  isLoading = false;
  testRuns: TestautomationTest[] = [];
  loadingTitle: string;
  loadingSubtitle: string;
  private notifier = new Subject();
  recentTestRun: TestautomationTest;
  activeTestId: number;
  testIsRunning: boolean;

  constructor(
    private testautomationService: TestautomationService,
  ) {
  }

  ngOnInit(): void {
    this.loadingTitle = 'modules.admin-section.testautomation.spinner-title';
    this.loadingSubtitle = 'modules.admin-section.testautomation.spinner-subtitle';
    this.getTests();
  }

  private getTests(): void {
    this.isLoading = true;
    this.testautomationService.getTestRuns()
      .subscribe(
        (testresponse: TestautomationTest[]) => {
            this.testRuns = testresponse;
            this.getRecentTestRun(testresponse);
            console.log(testresponse);
            this.getActiveTestRunId();
        },
        (error: any) => {
          console.error(error);
          this.isLoading = false;
        }
      );
  }

  private getRecentTestRun(testRuns:TestautomationTest[]): void {
    this.recentTestRun = testRuns.reduce((prev, current) => {
      let prevDate = new Date(prev.timeStamp);
      let currentDate = new Date(current.timeStamp);
      if (currentDate > prevDate) {
        return current;
      } else {
        return prev;
      }
    });
  }

  private getActiveTestRunId(){
    this.isLoading = true;
    this.testautomationService.getActiveTestRunId()
      .pipe(
        takeUntil(this.notifier)
      )
      .subscribe(
        (resp : number) =>{
          this.activeTestId = resp;
          this.checkForActiveTest(resp);
          this.isLoading = false;
        },
        (error: any) => {
          console.error(error);
          this.isLoading = false;
        }
      )
  }

  private checkForActiveTest(testId: number){
    testId === null ? this.testIsRunning = false : this.testIsRunning = true;
}

  ngOnDestroy() {
    this.notifier.complete();
  }
}
