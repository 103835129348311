import { HttpErrorResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppService } from '@core/services/app.service';
import { ErrorHandlerService } from '@core/services/errorHandler/error-handler.service';
import { SteuerdatenService } from '@core/services/steuerdaten.service';
import { StorageService } from '@core/services/storage.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgOption } from '@ng-select/ng-select';
import { TranslocoService } from '@ngneat/transloco';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import {
  ExtConstants,
  FlashFileExtension,
  FlashFileSpecialExtension,
} from '@shared/lists/flashFileExtension';
import { LogLevel } from '@shared/lists/logLevel';
import { specialSerie } from '@shared/lists/specialSerie';
import { ExpertToolCalculation } from '@shared/models/expertool-dynamic-models/ExpertToolCalculation';
import { VedocData } from '@shared/models/experttool/VedocData';
import { BdpName } from '@shared/models/filetype/BdpName';
import { BdpSerieFilter } from '@shared/models/filetype/BdpSerieFilter';
import { Subscription } from 'rxjs';

import { FcscBdpModalComponent } from '../fcsc-bdp-modal/fcsc-bdp-modal.component';
import { FcscFinlogComponent } from '../fcsc-finlog/fcsc-finlog.component';

@Component({
  selector: 'app-fcsc-flashware',
  templateUrl: './fcsc-flashware.component.html',
  styleUrls: ['./fcsc-flashware.component.scss'],
})
export class FcscFlashwareComponent implements OnInit, OnDestroy, OnChanges {
  @Input() vedocData!: VedocData;
  @Input() selectedSdpInput!: string;
  @Input() selectedBdpName!: string;
  @Input() finlog!: FcscFinlogComponent;
  @Output() selectSdpName = new EventEmitter<string>();
  @Output() selectBdpName = new EventEmitter<string>();
  @Output() selectedExtention = new EventEmitter<string>();
  @Output() selectedSumsCheck = new EventEmitter<boolean>();
  @Output() xentryReleaseList = new EventEmitter<any>();
  form!: UntypedFormGroup;
  selectedSdpName!: any;
  @Input() selectedBdpModalRow!: any;
  @Output() selectBdpModalRow = new EventEmitter<any>();
  formLoaded!: boolean;
  isChecked = true;
  isSums = false;
  sumsCheckVisible =false;
  isSectionCollapsed = true;
  subscription!: Subscription;
  collapesedCheck!: boolean;
  logLevelItems!: NgOption[];
  flashFileExtItems!: NgOption[];
  xentryReleasesItems: NgOption[] = [];
  bdpSerieFilter: BdpSerieFilter[] = [];
  selected: BdpSerieFilter[] = [];
  extractedSerie!: string;
  isSpecialSerie!: boolean;
  translatedPlaceholder!: string;
  @Input() fromReader!: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private modalService: NgbModal,
    private translocoService: TranslocoService,
    private storageService: StorageService,
    private steuerdatenService: SteuerdatenService,
    private notificationService: ErrorHandlerService,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.logLevelItems = LogLevel;
    this.extractedSerie =
      this.steuerdatenService.extractSerieFromFinVin(
        this.vedocData && this.vedocData.fin
      ) ||
      this.steuerdatenService.extractSerieFromFinVin(
        this.vedocData && this.vedocData.vin
      );

    this.isSpecialSerie = specialSerie.indexOf(this.extractedSerie) > -1;

    this.flashFileExtItems = this.isSpecialSerie
      ? JSON.parse(JSON.stringify(FlashFileSpecialExtension))
      : FlashFileExtension;
    this.handleSpecialSerie();
  }

  checkUnselected() {
    return this.form.get('fileExt')?.value === this.translatedPlaceholder;
  }

  handleExtentionChange(event): void {
    this.selectedExtention.emit(event.name);
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.form && this.form.get('sdpName')) {
      this.form.get('sdpName')?.setValue(this.selectedSdpInput);
      this.form.get('bdpName')?.setValue(this.selectedBdpName);
      this.xentryReleasesItems = [];
    }
  }
  ngOnDestroy() {
    this.subscription?.unsubscribe();
  }

  getXentryReleases(sdpName: string) {
    this.steuerdatenService.getRcddbLookupXentryReleases(sdpName).subscribe(
      (data: string[]) => {
        if (data) {
          data.sort().reverse();
          data.forEach((dt) => {
            this.xentryReleasesItems = [
              ...this.xentryReleasesItems,
              { name: dt, value: dt },
            ];
          });
          if (this.fromReader){
            this.emitXentryReleaseData(data)
          }
        }
      },
      (error: HttpErrorResponse) => {
        this.form?.patchValue({
          xentryReleases: '',
        });

        this.notificationService.handleServerErrorNotification(
          error,
          'modules.admin-section.config-modal.insert-edit-api-error-message'
        );
      }
    );
  }

  private createForm() {
    const flashForm =
      this.storageService.getData(sessionStorageKeys.flashForm) || {};
    if (flashForm.sdpName) {
      this.selectedSdpName = flashForm.sdpName;
      this.selectedBdpName = flashForm.bdpName;
    }
    if (this.selectedSdpInput) {
      this.selectedSdpName = this.selectedSdpInput;
      this.selectedBdpName = this.selectedBdpName;
    }
    this.form = this.formBuilder.group({
      logLevel: new UntypedFormControl(flashForm.logLevel || '2'),
      fileExt: new UntypedFormControl({
        value: this.isSpecialSerie
          ? this.translatedPlaceholder
          : flashForm.fileExt || ExtConstants.DEFAULT,
        disabled: !this.isSpecialSerie,
      }),
      sdpName: new UntypedFormControl(
        this.selectedSdpInput || flashForm.sdpName || ''
      ),
      bdpName: new UntypedFormControl(this.selectedBdpName || flashForm.bdpName || ''),
      xentryReleases: new UntypedFormControl(
        flashForm.xentryReleases || '2100.10.000'
      ),
      abfragen: new UntypedFormControl(
        flashForm.hasOwnProperty('abfragen') ? flashForm.abfragen : true
      ),
      aktivieren: new UntypedFormControl(
        flashForm.hasOwnProperty('aktivieren') ? flashForm.aktivieren : false
      ),
    });
    this.storageService.saveData(sessionStorageKeys.flashForm, this.form.value);
    this.subscription = this.form.valueChanges.subscribe((formValues) => {
      this.storageService.saveData(sessionStorageKeys.flashForm, formValues);
    });
    this.getDefaultBdpBySerie();
    this.formLoaded = true;
  }

  openModal() {
    const modalRef = this.modalService.open(FcscBdpModalComponent, {
      size: 'lg',
      windowClass: 'details-modal override',
      backdrop: 'static',
      keyboard: false,
    });
    modalRef.componentInstance.selectedFin =
      this.vedocData.fin || this.vedocData.vin;
    modalRef.componentInstance.title = this.translocoService.translate(
      'modules.expert-tool.modal-bdp-title'
    );
    modalRef.componentInstance.subText = this.translocoService.translate(
      'modules.expert-tool.modal-bdp-sub-title'
    );
    modalRef.componentInstance.ref = this;
    modalRef.componentInstance.finlog = this.finlog;
    modalRef.componentInstance.selectedSdpName = this.selectedSdpName;
    modalRef.result.then((result) => {
      this.activate(result);
    });
  }

  handleActivation(result): void {
    this.activate(result);
  }

  activate(result) {
    if (result && result.length) {
      this.selectedSdpName = result[0].sdpName;
      this.selectedBdpName = result[0].bdpName;
      this.getXentryReleases(this.selectedSdpName);
      if (this.xentryReleasesItems) {
        this.form?.patchValue({
          xentryReleases: '2100.10.000',
        });
      }
      this.storageService.saveData(
        sessionStorageKeys.flashForm,
        this.form.value
      );
      this.storageService.saveData(
        sessionStorageKeys.selectedBdpName,
        this.selectedBdpName
      );
      this.selectBdpName.emit(this.selectedBdpName);
      this.selectSdpName.emit(this.selectedSdpName);
      this.selectedBdpModalRow = result[0];
      this.storageService.saveData(
        sessionStorageKeys.selectedBdpModalRow,
        this.selectedBdpModalRow
      );
      this.selectBdpModalRow.emit(this.selectedBdpModalRow);
      this.form.get('sdpName')?.setValue(this.selectedSdpName);
    }
  }

  handleCheck(): void {
    const checkControl = this.form.get('abfragen');

    checkControl?.setValue(!checkControl.value);
    this.isChecked = !this.isChecked;
  }

  handleSumsCheck(): void {
    const check = this.form.get('aktivieren');

    check?.setValue(!check.value);
    this.isSums = !this.isSums;
    this.selectedSumsCheck.emit(this.isSums);
  }

  private getDefaultBdpBySerie(): void {
    this.steuerdatenService.getBdpBySerie(this.getExtractedSerie()).subscribe(
      (data: BdpSerieFilter[]) => {
        if (data) {
          this.bdpSerieFilter = data;
          const sorted = this.sortBySmokeTestStatus();
          const serieIndex = sorted.findIndex((st) =>
            st.bdpName.startsWith(BdpName.SERIEN)
          );
          const autoSelected = [sorted[serieIndex]];
          this.selected = [...autoSelected];

          this.selectedSdpName = this.selected[0] && this.selected[0].sdpName;
          this.selectSdpName.emit(this.selectedSdpName);

          this.selectedBdpName = this.selected[0] && this.selected[0].bdpName;
          this.selectBdpName.emit(this.selectedBdpName);
          this.getXentryReleases(this.selectedSdpName);
        }
      },
      (error: HttpErrorResponse) => {
        this.selectedSdpName = '';
        this.selectedBdpName = '';
        this.selectSdpName.emit(this.selectedSdpName);
        this.selectBdpName.emit(this.selectedBdpName);
        this.notificationService.handleServerErrorNotification(
          error,
          'global.bdp-status.service-down'
        );
      }
    );
  }

  private getExtractedSerie(): string {
    const filteredSerie = `C${
      this.steuerdatenService.extractSerieFromFinVin(
        this.vedocData && this.vedocData.fin
      ) ||
      this.steuerdatenService.extractSerieFromFinVin(
        this.vedocData && this.vedocData.vin
      )
    }`;
    return filteredSerie;
  }

  private sortBySmokeTestStatus(): BdpSerieFilter[] {
    return this.bdpSerieFilter.sort((a, b) =>
      a.smokeTestStatus < b.smokeTestStatus
        ? 1
        : b.smokeTestStatus < a.smokeTestStatus
        ? -1
        : 0
    );
  }

  private handleSpecialSerie() {
    if (this.isSpecialSerie) {
      this.appService
        .translateKey('modules.data-management.select-option')
        .subscribe((translation) => {
          this.flashFileExtItems = JSON.parse(
            JSON.stringify(FlashFileSpecialExtension)
          );
          this.flashFileExtItems.unshift({ name: translation });
          this.translatedPlaceholder = translation;
          this.selectedExtention.emit(translation);
          this.createForm();
        });
    } else {
      this.selectedExtention.emit('');
      this.createForm();
    }
  }

  emitXentryReleaseData(data : string[]){
    this.xentryReleaseList.emit(data)
  }
}
