<div class="row">
  <div class="col-12">
    <div class="vus-container">
      <ngx-datatable
        #dataTable
        class="material datatable-scrollable"
        [columnMode]="'flex'"
        [headerHeight]="56"
        rowHeight="auto"
        [scrollbarH]="false"
        [scrollbarV]="false"
        [rows]="steuerDaten"
        [selected]="selected"
        [selectionType]="dataTableSelection"
        [selectAllRowsOnPage]="true"
        (select)="onSelect($event)"
        [footerHeight]="56"
        (page)="setPage($event)"
        (sort)="sortCallback($event)"
        [sorts]="sortConfig"
        [messages]="{ emptyMessage: emptyMessage }"
      >
        <ngx-datatable-column
          [cellClass]="'text-center'"
          name=""
          prop="selected"
          [flexGrow]="0"
          [sortable]="false"
          [canAutoResize]="false"
          [draggable]="false"
          [resizeable]="false"
          [width]="22"
        >
          <ng-template
            ngx-datatable-cell-template
            let-value="value"
            let-isSelected="isSelected"
            let-onCheckboxChangeFn="onCheckboxChangeFn"
          >
            <label class="checkradio-container">
              <input
                type="radio"
                [checked]="isSelected"
                (change)="onCheckboxChangeFn($event)"
              />
              <span class="radiomark"></span>
            </label>
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="SDP"
          prop="name"
          [flexGrow]="4"
          [resizeable]="false"
        >
          <ng-template
            let-value="value"
            let-row="row"
            let-rowIndex="rowIndex"
            ngx-datatable-cell-template
          >
            <span
              *ngIf="value"
              class="text-pre-wrap"
              placement="bottom"
              [ngbTooltip]="value"
              data-container="body"
              container="body"
              tooltipClass="note-tooltip"
              >{{ value }}</span
            >
          </ng-template>

          <ng-template
            let-value="value"
            let-column="column"
            let-rowIndex="rowIndex"
            let-sort="sortFn"
            ngx-datatable-header-template
          >
            <span
              class="header-sort datatable-header-cell-label"
              ngbTooltip="{{
                'modules.data-management.table-header.sd-packet'
                  | transloco: { value: value }
              }}"
              data-container="body"
              container="body"
              placement="bottom"
              tooltipClass="note-tooltip"
              (click)="sort()"
              >{{ column.name }}</span
            >
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'global.uploaddate' | transloco }}"
          prop="createdAt"
          [flexGrow]="3"
          [resizeable]="false"
        >
          <ng-template
            let-value="value"
            let-row="row"
            ngx-datatable-cell-template
          >
            <span *ngIf="value">{{ value | date: "dd.MM.yy HH:mm:ss" }}</span>
          </ng-template>

          <ng-template
            let-value="value"
            let-column="column"
            let-rowIndex="rowIndex"
            let-sort="sortFn"
            ngx-datatable-header-template
          >
            <span
              class="header-sort"
              ngbTooltip="{{
                'modules.data-management.table-header.upload-date'
                  | transloco: { value: value }
              }}"
              data-container="body"
              container="body"
              placement="bottom"
              tooltipClass="note-tooltip"
              (click)="sort()"
              >{{ column.name }}</span
            >
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          [cellClass]="'text-right'"
          name="System / User"
          prop="username"
          [flexGrow]="3"
          [resizeable]="false"
        >
          <ng-template
            let-value="value"
            let-row="row"
            ngx-datatable-cell-template
          >
            <span *ngIf="value">{{ value }}</span>
          </ng-template>

          <ng-template
            let-value="value"
            let-column="column"
            let-rowIndex="rowIndex"
            let-sort="sortFn"
            ngx-datatable-header-template
          >
            <span
              class="header-sort"
              ngbTooltip="{{
                'modules.data-management.table-header.system'
                  | transloco: { value: value }
              }}"
              data-container="body"
              container="body"
              placement="bottom"
              tooltipClass="note-tooltip"
              (click)="sort()"
              >{{ column.name }}</span
            >
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
          name="{{ 'modules.data-management.table-header.status' | transloco }}"
          prop="status"
          [flexGrow]="5"
          [resizeable]="false"
        >
          <ng-template
            let-value="value"
            let-row="row"
            ngx-datatable-cell-template
          >
            <div class="status-box">
              <div>
                <span
                  [ngStyle]="{'color': getColor(value)}"
                >
                  <i class="bi bi-circle-fill"></i>
                </span>
              </div>
              <div class="status-text" *ngIf="value">{{ getValue(value) }}</div>
            </div>
          </ng-template>

          <ng-template
            let-value="value"
            let-column="column"
            let-rowIndex="rowIndex"
            let-sort="sortFn"
            ngx-datatable-header-template
          >
            <span
              class="header-sort"
              ngbTooltip="{{
                'modules.data-management.table-header.status'
                  | transloco: { value: value }
              }}"
              data-container="body"
              container="body"
              placement="bottom"
              tooltipClass="note-tooltip"
              (click)="sort()"
              >{{ column.name }}</span
            >
          </ng-template>
        </ngx-datatable-column>

        <ngx-datatable-column
        name="{{ 'global.comment' | transloco }}"
        prop="note"
        [flexGrow]="7"
        [resizeable]="false"
      >
        <ng-template
          let-value="value"
          let-row="row"
          ngx-datatable-cell-template
        >
          <span
            *ngIf="value"
            class="text-pre-wrap"
            placement="bottom"
            [ngbTooltip]="value"
            data-container="body"
            container="body"
            tooltipClass="note-tooltip"
            >{{ truncateNote(value) }}</span
          >
        </ng-template>

        <ng-template
          let-value="value"
          let-column="column"
          let-rowIndex="rowIndex"
          let-sort="sortFn"
          ngx-datatable-header-template
        >
          <span
            class="header-sort"
            ngbTooltip="{{
              'modules.data-management.table-header.comment'
                | transloco: { value: value }
            }}"
            data-container="body"
            container="body"
            placement="bottom"
            tooltipClass="note-tooltip"
            (click)="sort()"
            >{{ column.name }}</span
          >
        </ng-template>
      </ngx-datatable-column>

        <ngx-datatable-footer *ngIf="true">
          <ng-template
            ngx-datatable-footer-template
            let-rowCount="rowCount"
            let-pageSize="pageSize"
            let-selectedCount="selectedCount"
            let-offset="offset"
            let-curPage="curPage"
          >
            <div class="col-8">
              <datatable-pager
                [pagerLeftArrowIcon]="'datatable-icon-left'"
                [pagerRightArrowIcon]="'datatable-icon-right'"
                [pagerPreviousIcon]="'datatable-icon-prev'"
                [pagerNextIcon]="'datatable-icon-skip'"
                [count]="response?.totalElements"
                [page]="page.pageNumber"
                [size]="page.size"
                (change)="setPage($event)"
              >
              </datatable-pager>
            </div>

            <div class="col-4 page-result-section server-side">
              <span class="float-end">
                <span class="result-text">{{
                  "components.footer.result-per-page" | transloco
                }}</span>
                <span class="result-value" [ngClass]="{'active' : page.size === 10}" (click)="setPageSize(10)">10</span>|
                <span class="result-value margin-override" [ngClass]="{'active' : page.size === 30}" (click)="setPageSize(30)">30</span>|
                 <span class="result-value" [ngClass]="{'active' : page.size === 100}" (click)="setPageSize(100)">100</span>
                 <span>
                  {{ "components.footer.files" | transloco }}:
                  {{
                      (response?.totalElements > 0 ? ((+response?.number) * page.size)  + 1 : 0)
                  }}
                  -
                  {{
                    isNaN((+response?.number * page.size) + (response?.numberOfElements)) ? 0 :
                    (+response?.number * page.size) + (response?.numberOfElements)
                  }}
                </span>
                <span>
                  {{ "components.footer.of" | transloco }}
                  {{ response?.totalElements ? response?.totalElements : 0 }}</span
                >
              </span>
            </div>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</div>
