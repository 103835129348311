import {Component, Input, OnInit} from '@angular/core';
import {TestautomationService} from "@core/services/testautomation.service";
import {TranslocoService} from "@ngneat/transloco";
import {Observable, Observer} from "rxjs";

@Component({
  selector: 'app-test-start',
  templateUrl: './test-start.component.html',
  styleUrls: ['./test-start.component.scss']
})
export class TestStartComponent implements OnInit {
  @Input() testIsRunning: boolean;
  isLoading = false;
  loadingTitle: string;
  loadingSubtitle: string;

  constructor(
    private testautomationService: TestautomationService,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit(): void {
    this.loadingTitle = 'modules.admin-section.testautomation.test-start.spinner-title';
    this.loadingSubtitle = 'modules.admin-section.testautomation.test-start.spinner-subtitle';
  }

  startTest(){
    this.isLoading =true;
    this.testautomationService.startTestRun().subscribe(
      response =>{
        console.log(this.translocoService.translate(
          'modules.admin-section.testautomation.test-start.start-success', response
        ));
        this.getActiveTestRunIdWithTimeout().subscribe(
          (response: number) => {
            console.log(response);
            this.isLoading = false;
            window.location.reload();
          },
          (error: any) => {
            console.error(error);
          }
        );

      },
      error => {
        console.error(this.translocoService.translate(
          'modules.admin-section.testautomation.test-start.start-fail', error
        ));
      }
    );
  }

  getActiveTestRunIdWithTimeout(){
    return new Observable<number>((observer: Observer<number>) => {
      const makeBackendCall = () => {
        this.testautomationService.getActiveTestRunId().subscribe(
          (response: number) => {
            if (response !== null) {
              observer.next(response);
              observer.complete();
            } else {
              setTimeout(() => {
                makeBackendCall();
              }, 3000);
            }
          },
          (error: any) => {
            observer.error(error);
          }
        );
      };
      makeBackendCall();
    });

  }
}
