<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>

<div class="outer-container">
  <div class="row">
    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
          #dataTable
          class="material datatable-scrollable"
          [columnMode]="'flex'"
          [headerHeight]="56"
          rowHeight="auto"
          [scrollbarH]="false"
          [scrollbarV]="false"
          [rows]="vp5VerionsData"
          [sorts]="sortConfig"
        >
          <ngx-datatable-column
            [cellClass]="'text-right'"
            name="{{
              'modules.data-management.test-data.vp5.vp5-version' | transloco
            }}"
            prop="version"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.test-data.vp5.vp5-version'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{
              'modules.admin-section.vp-versions.activated-at' | transloco
            }}"
            prop="activatedAt"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value | date : "dd.MM.yy HH:mm:ss" }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.admin-section.vp-versions.activated-at'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [cellClass]="'text-right'"
            name="{{
              'modules.admin-section.vp-versions.activated-from' | transloco
            }}"
            prop="activatedFrom"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.admin-section.vp-versions.activated-from'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'modules.admin-section.vp-versions.status' | transloco }}"
            prop="status"
            [flexGrow]="4"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.admin-section.vp-versions.status'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>

            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <div *ngIf="value">
                {{ getVP5ActivationStatusValue(value) }}
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [cellClass]="'text-left'"
            name="{{ 'global.actions' | transloco }}"
            [flexGrow]="4"
            [sortable]="false"
            [resizeable]="false"
          >
            <ng-template
              let-rowIndex="rowIndex"
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span 
                *ngIf="row.status !== activeStatus"
                title="{{
                  'modules.admin-section.vp-versions.activate'
                    | transloco
                }}"
                (click)="activateVp5Version(row.version)"
              >
                <i class="bi bi-play-fill"></i>
              </span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              ngx-datatable-header-template
            >
              <span
                ngbTooltip="{{
                  'global.actions' | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>
