<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>

<div class="modal-header">
  <span class="modal-title">{{ title }}</span>
</div>

<div class="modal-sub-text">{{ subText }}</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
          #dataTable
          class="material datatable-scrollable"
          [columnMode]="'flex'"
          [headerHeight]="56"
          rowHeight="auto"
          [scrollbarH]="false"
          [scrollbarV]="false"
          [rows]="bdpSerieFilter"
          [sorts]="sortConfig"
          [selected]="selected"
          [selectionType]="dataTableSelection"
          [selectAllRowsOnPage]="true"
          (select)="onSelect($event)"
          (sort)="onSort($event)"
        >
          <ngx-datatable-column
            [cellClass]="'text-center'"
            name=""
            prop="selected"
            [flexGrow]="0"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            [width]="22"
          >
            <ng-template
              ngx-datatable-cell-template
              let-value="value"
              let-isSelected="isSelected"
              let-onCheckboxChangeFn="onCheckboxChangeFn"
            >
              <label class="checkradio-container">
                <input
                  type="radio"
                  [checked]="isSelected"
                  (change)="onCheckboxChangeFn($event)"
                />
                <span class="radiomark"></span>
              </label>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="SDP"
            prop="sdpName"
            [flexGrow]="5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              let-rowIndex="rowIndex"
              ngx-datatable-cell-template
            >
              <span
                *ngIf="value"
                class="text-pre-wrap"
                placement="bottom"
                [ngbTooltip]="value"
                data-container="body"
                container="body"
                tooltipClass="note-tooltip"
                >{{ value }}</span
              >
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{ 'modules.data-management.table-header.sd-packet' | transloco: { value: value } }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{column.name}}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.uploaddate' | transloco }}" 
            prop="createdAt"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value | date: "dd.MM.yy HH:mm:ss" }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{ 'modules.data-management.table-header.upload-date' | transloco: { value: value } }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{column.name}}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [cellClass]="'text-right'"
            name="System / User"
            prop="username"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{ 'modules.data-management.table-header.system' | transloco: { value: value } }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{column.name}}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'modules.data-management.table-header.status' | transloco }}"
            prop="smokeTestStatus"
            [flexGrow]="5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <div class="status-box">
                <div>
                  <span
                    [ngStyle]="{'color': getColor(value)}"
                  >
                    <i class="bi bi-circle-fill"></i>
                  </span>
                </div>
                <div class="status-text" *ngIf="value">{{ getValue(value) }}</div>
              </div>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{ 'modules.data-management.table-header.status' | transloco: { value: value } }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{column.name}}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
          name="{{ 'global.bdp-status.title' | transloco }}"
          prop="bdpStatus"
          [flexGrow]="3"
          [resizeable]="false"
        >
          <ng-template
            let-value="value"
            let-column="column"
            let-rowIndex="rowIndex"
            let-sort="sortFn"
            ngx-datatable-header-template
          >
            <span
              class="header-sort"
              ngbTooltip="{{
                'global.bdp-status.tooltip' | transloco: { value: value }
              }}"
              data-container="body"
              container="body"
              placement="bottom"
              tooltipClass="note-tooltip"
              (click)="sort()"
              >{{ column.name }}</span
            >
          </ng-template>

          <ng-template
            let-value="value"
            let-row="row"
            ngx-datatable-cell-template
          >
            <div class="status-box">
              <div class="status-text" *ngIf="value">{{ getBDPValue(value) }}</div>
            </div>
          </ng-template>
        </ngx-datatable-column>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-end">
          <div class="displayed">
            <button type="button" class="btn btn-secondary" (click)="cancel()">
              {{ 'global.cancel' | transloco }}
            </button>
            <button type="button" class="btn btn-primary" (click)="save()">
              {{ 'modules.expert-tool.btn-choose-packet' | transloco }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
