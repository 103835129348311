import { Injectable } from '@angular/core';
import { AttributesVpRate } from '@core/enums/attributes-vp-rate.enum';
import { DiebstahlkennerStatus } from '@core/enums/diebstahlkenner.enum';
import { FlashwareBedarf } from '@core/enums/flashware-bedarf.enum';
import { TranslocoService } from '@ngneat/transloco';
import { expertToolConstants } from '@shared/constants/ExpertToolConstants';
import { sessionStorageKeys } from '@shared/constants/SessionStorageKeys';
import {
  ExceludedDynamicKeys,
  ExceludedFinlogPopulation,
  ExceludedVedocAttrib,
} from '@shared/lists/ExperttoolExceludedKeys';
import { ExtConstants } from '@shared/lists/flashFileExtension';
import { AscMetadata } from '@shared/models/AscMetadata';
import { ControlUnits } from '@shared/models/ControlUnits';
import { ExpertToolCalculation } from '@shared/models/expertool-dynamic-models/ExpertToolCalculation';
import { CalculateApplication } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateApplication';
import { CalculateClient } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateClient';
import { CalculateDatapool } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateDatapool';
import { CalculateDestination } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateDestination';
import { CalculateFinlog } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateFinlog';
import { CalculateKeyValGeneric } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateKeyValGeneric';
import { CalculateLocale } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateLocale';
import { CalculatePayload } from '@shared/models/expertool-dynamic-models/sub-parts/CalculatePayload';
import { CalculateTask } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateTask';
import { CalculateTracking } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateTracking';
import { CalculateUser } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateUser';
import { CalculateVedocRequest } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateVedocRequest';
import { CalculateVehicle } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateVehicle';
import { CalculateVehicleData } from '@shared/models/expertool-dynamic-models/sub-parts/CalculateVehicleData';
import { FlashwareForm } from '@shared/models/experttool/FlashwareForm';
import { VedocData } from '@shared/models/experttool/VedocData';
import { FlashwareResponse } from '@shared/models/flashwareResponse';

import { ErrorHandlerService } from '../errorHandler/error-handler.service';
import { SteuerdatenService } from '../steuerdaten.service';
import { StorageService } from '../storage.service';

@Injectable({
  providedIn: 'root',
})
export class ExperttoolDataModelingService {
  /**
   *  @description
   *  Utilize the calculationRequestData prporety from this service where needed
   */
  public calculationRequestData: ExpertToolCalculation | null;
  public baureiheList: any;
  constructor(
    private readonly steuerdatenService: SteuerdatenService,
    private translocoService: TranslocoService,
    private storageService: StorageService,
    private notificationService: ErrorHandlerService,
  ) {}

  /* data modeling methods */

  createExpertToolCalculation(
    metadata: AscMetadata,
    finLog: CalculateFinlog,
  ): ExpertToolCalculation {
    const tracking = this.createTracking(metadata);
    const client = this.createClient(metadata);
    const destination = this.createDestination(metadata);
    const user = this.createUser(metadata);

    const payload = this.createPayload(metadata, finLog);
    return new ExpertToolCalculation(
      tracking,
      client,
      destination,
      user,
      payload,
    );
  }

  private createTracking(metadata: AscMetadata): CalculateTracking {
    return new CalculateTracking(metadata.sessionID, metadata.requestID);
  }

  private createClient(metadata: AscMetadata): CalculateClient {
    const platform = 'platform';
    const dateTime = new Date().toISOString();
    const locale = this.createCalculateLocale(metadata);
    const application = this.createCalculateApplication(metadata);
    const task = this.createCalculateTask(metadata);
    return new CalculateClient(platform, dateTime, locale, application, task);
  }

  private createDestination(metadata: AscMetadata): CalculateDestination {
    const service = metadata.destination.service;
    const action = metadata.destination.action;
    return new CalculateDestination(service, action);
  }

  private createUser(metadata: AscMetadata): CalculateUser {
    const userid = metadata.user.userID;
    const organisationid = metadata.user.organisationID;
    return new CalculateUser(userid, organisationid);
  }

  private createPayload(
    metadata: AscMetadata,
    finlog: CalculateFinlog,
  ): CalculatePayload {
    const vehicleData = this.createCalculateVehicleData(metadata);
    const vedocrequest = this.createCalculateVedocRequest(metadata);
    const dataPool = this.createCalculateDatapool(metadata);
    const finlogCreaion = this.createCalculateFinlog(finlog);

    return new CalculatePayload(
      vehicleData,
      vedocrequest,
      dataPool,
      finlogCreaion,
    );
  }

  private createCalculateFinlog(data: CalculateFinlog): CalculateFinlog {
    const metadata = data.metadata;
    const ecus = data.ecus;
    return new CalculateFinlog(metadata, ecus);
  }

  private createCalculateVehicle(metadata: AscMetadata): CalculateVehicle {
    const category = metadata.payload.category;
    return new CalculateVehicle(category);
  }

  private createCalculateDatapool(metadata: AscMetadata): CalculateDatapool {
    const poolIdentifier = metadata.payload.poolIdentifier;
    return new CalculateDatapool(poolIdentifier);
  }

  private createCalculateLocale(metadata: AscMetadata): CalculateLocale {
    const language = metadata.locale.language || 'de';
    const country = 'DE';
    return new CalculateLocale(language, country);
  }

  private createCalculateApplication(
    metadata: AscMetadata,
  ): CalculateApplication {
    const name = metadata.application.name;
    const version = metadata.application.version;
    return new CalculateApplication(name, version);
  }

  private createCalculateTask(metadata: AscMetadata): CalculateTask {
    const process = metadata.task.process;
    const mode = metadata.task.mode;
    return new CalculateTask(process, mode);
  }

  private createCalculateVedocRequest(
    metadata: AscMetadata,
  ): CalculateVedocRequest {
    const doRequest = metadata.doRequest;
    return new CalculateVedocRequest(doRequest);
  }

  private createCalculateVehicleData(metadata): CalculateVehicleData {
    const vehicle = this.createCalculateVehicle(metadata);
    return new CalculateVehicleData(vehicle);
  }

  /* data handling and mapping methods */

  generateFinlogMetaSection(headerEtn, vedocData): CalculateKeyValGeneric[] {
    const headersSection: CalculateKeyValGeneric = this.populateFinlogHeader(
      headerEtn.header,
    );
    const attribSection: CalculateKeyValGeneric = this.populateFinlogAttrib(
      vedocData.attributes ? vedocData.attributes[0] : '',
    );
    const etnSection: CalculateKeyValGeneric = this.populateFinlogEtn(
      headerEtn.etn,
    );
    return [headersSection, attribSection, etnSection];
  }

  generateFinlogEcusSection(
    selected,
    response,
  ): CalculateKeyValGeneric[] | CalculateKeyValGeneric[][] {
    const ecuSection = this.populateFinlogEcu(selected, response);
    return [ecuSection] as
      | CalculateKeyValGeneric[]
      | CalculateKeyValGeneric[][];
  }

  generateFinlogCombinedSection(metadata, ecus): CalculateFinlog {
    const mergedEcus = []?.concat.apply([], ecus);
    return new CalculateFinlog(metadata, mergedEcus);
  }

  setHeaderEtn(headerEtn, flashForm, vedocData, specialSerie) {
    if(this.storageService.getData(sessionStorageKeys.readType) == "loadFromFINNumber") {
      headerEtn.header.LogLevel = flashForm.logLevel;
      headerEtn.header.FlashData = flashForm.xentryReleases;
      const ascMetadata = new AscMetadata();   
      headerEtn.header.SymptomVP = ascMetadata.payload.SymptomVP;
      headerEtn.header.SPARTE = ascMetadata.payload.sparte;
      headerEtn.header.SourceSystem = ascMetadata.payload.sourceSystem;
      headerEtn.header.BR =
        this.steuerdatenService.extractSerieFromFinVin(
          vedocData && vedocData.fin,
        ) ||
        this.steuerdatenService.extractSerieFromFinVin(
          vedocData && vedocData.vin,
        );

      const isSpecialSerie = specialSerie.indexOf(headerEtn.header.BR) > -1;
      if (isSpecialSerie) {
        headerEtn.header.FlashFileExtension =
          flashForm.fileExt !== ExtConstants.DEFAULT
            ? flashForm.fileExt && flashForm.fileExt.split('.').join('')
            : '';
      }
      headerEtn.header.FIN = vedocData.fin ? vedocData.fin : '';
      headerEtn.header.VIN = vedocData.vin ? vedocData.vin : '';
      headerEtn.header.DIEBKZ =
      vedocData.status as keyof typeof DiebstahlkennerStatus;
    }
    else if(this.storageService.getData(sessionStorageKeys.readType) == "loadFromFINLog") {
      const calculationData = this.storageService.getData(sessionStorageKeys.calculationData);
      calculationData.payload.finLog.metadata[0]['value'].forEach(elem => {
        if(!(elem['key'] in headerEtn.header)) {
          headerEtn.header[elem['key']] = elem['value'];
        }
      });
    }

    if (headerEtn && headerEtn.etn) {
      headerEtn.etn.TTZ = vedocData.ttz ? vedocData.ttz : '';
      headerEtn.etn.FIN_Vehicle = vedocData.vin ? vedocData.vin : '';
      headerEtn.etn.FIN_Internal = vedocData.fin ? vedocData.fin : '';
      headerEtn.etn.SACode = vedocData.codes ? vedocData.codes.join('+') : '';
    }
    return headerEtn;
  }

  updateEcus(ecus, orignalLength?) {
    const controlArray: ControlUnits[] = [];
    ecus?.forEach((ecu, index) => {
      const vpRateModules = this.findDynamicValueFromKey(
        ecu,
        expertToolConstants.keys.vpRateModules,
      );
      const fwmbs = this.findDynamicValueFromKey(
        ecu,
        expertToolConstants.keys.fwmbs,
      );
      const diogName = this.findDynamicValueFromKey(
        ecu,
        expertToolConstants.keys.sgDiogname,
      ) as string;
      const sgRefnr = this.findDynamicValueFromKey(
        ecu,
        expertToolConstants.keys.sgRefnr,
      ) as string;

      const excludekeys = ExceludedFinlogPopulation;
      const dynamicExcludeKeys = ExceludedDynamicKeys;
      const allKeys = ecu.map((ec) => ec.key);
      let attribute = this.getControlUnitAttributes(vpRateModules as string);
      const swSnrs: string[] = fwmbs as string[];
      allKeys.forEach((key) => {
        if (excludekeys.indexOf(key) === -1) {
          const found = dynamicExcludeKeys.filter((dkey) => key.includes(dkey));
          if (!found.length) {
            const ecuValue = this.findDynamicValueFromKey(ecu, key);
            attribute = attribute + '\n' + key + '=' + ecuValue;
          }
        }
      });

      const controlUnit: ControlUnits = {
        id: index + 1 + (orignalLength ? orignalLength : 0),
        diogName,
        swSnrs,
        hwSnr: sgRefnr,
        disabled: false,
        selected: true,
        attributes: attribute,
        visibleCheck:
          attribute !==
          this.translocoService.translate(AttributesVpRate.BY_PROIRITY),
      };
      controlArray.push(controlUnit);
    });
    return controlArray;
  }

  getResultFlashStatus(ecuObj: FlashwareResponse): string {
    const sgdowngrade = ecuObj.SG_DOWNGRADE;
    const sgNewFlash = ecuObj.SG_NEWFLASHSW;
    const fittingFlashw = ecuObj.SG_FITTINGFLASHSW;
    if (
      sgdowngrade &&
      ecuObj.SG_DOWNGRADE === expertToolConstants.general.yes
    ) {
      return FlashwareBedarf.DOWNGRADE;
    } else if (sgNewFlash && sgNewFlash.length > 0) {
      return FlashwareBedarf.THERE;
    } else if (fittingFlashw && fittingFlashw.length > 0) {
      return FlashwareBedarf.NOTTHERE;
    } else {
      return FlashwareBedarf.NORESULT;
    }
  }

  getJoinAndSplit(value, joiner, splitter) {
    return value ? value.split(splitter).join(joiner) : '';
  }

  getHeaderFromResponse(
    data: ExpertToolCalculation | null,
  ):
    | string
    | string[]
    | CalculateKeyValGeneric[]
    | number
    | CalculateKeyValGeneric {
    const header = this.findDynamicValueFromKey(
      data?.payload.finLog.metadata,
      expertToolConstants.keys.header,
    );
    return header;
  }

  findDynamicValueFromKey(
    list: CalculateKeyValGeneric[] | undefined,
    key: string,
  ):
    | string
    | string[]
    | CalculateKeyValGeneric[]
    | number
    | CalculateKeyValGeneric {
    const found: CalculateKeyValGeneric | undefined | string = list
      ? list?.find((obj) => obj.key === key)
      : '';
    return found ? (found as CalculateKeyValGeneric).value : '';
  }

  mapArraytoObject(list) {
    const obj = {};
    list.forEach((element) => {
      obj[element.key] = element.value;
    });
    return obj;
  }

  mapDataToPayloadEcus(data, list, excludeList, func) {
    const keys = Object.keys(data);
    const eachRow = list.find((lt) => {
      return lt.find(
        (slt) =>
          slt.key === 'id' && slt.value.toString() === data.id.toString(),
      );
    });
    keys.forEach((key) => {
      const dataFromList = eachRow?.find((elem) => elem.key === key);
      const val = func(data[key], key);
      if (eachRow) {
        if (dataFromList) {
          dataFromList.value = val;
        } else {
          if (excludeList.indexOf(key) === -1) {
            const obj: CalculateKeyValGeneric = new CalculateKeyValGeneric(
              key,
              val,
            );
            eachRow.push(obj);
          }
        }
      }
    });
    return list;
  }

  addDataToPayloadEcus(data, list, excludeList, func) {
    const keys = Object.keys(data);
    const eachRow: CalculateKeyValGeneric[] = [];
    keys.forEach((key) => {
      const val = func(data[key], key);
      if (excludeList.indexOf(key) === -1) {
        const obj: CalculateKeyValGeneric = new CalculateKeyValGeneric(
          key,
          val,
        );
        eachRow.push(obj);
      }
    });
    list.push(eachRow);
    return list;
  }

  updateFinlogMetaData(
    etn: CalculateKeyValGeneric[],
    header: CalculateKeyValGeneric[],
    attrib: CalculateKeyValGeneric[],
  ) {
    const fin = this.findDynamicValueFromKey(
      header,
      expertToolConstants.keys.fin,
    ) as string;
    const vin = this.findDynamicValueFromKey(
      header,
      expertToolConstants.keys.vin,
    ) as string;
    const status = this.findDynamicValueFromKey(
      header,
      expertToolConstants.keys.diebkz,
    ) as string;
    const ttz = this.findDynamicValueFromKey(
      etn,
      expertToolConstants.keys.ttz,
    ) as string;
    const codes = this.findDynamicValueFromKey(
      etn,
      expertToolConstants.keys.saCode,
    ) as string;
    const ascMetadata = new AscMetadata();

    const vedocData: VedocData = {
      fin,
      vin,
      status,
      ttz,
      codes: codes?.split('+'),
      attributes: this.setFinlogAttrib(attrib) as string[],
      controlUnits: [],
      sparte: ascMetadata.payload.sparte,
    };

    return vedocData;
  }

  updateFlashForm(data: ExpertToolCalculation) {
    const flashForm = new FlashwareForm();

    const header = this.findDynamicValueFromKey(
      data.payload.finLog.metadata,
      expertToolConstants.keys.header,
    );
    
    flashForm.logLevel = this.findDynamicValueFromKey(
        header as CalculateKeyValGeneric[],
        expertToolConstants.keys.logLevel,
      ) as string;
    
    if (this.findDynamicValueFromKey(
      header as CalculateKeyValGeneric[],
      expertToolConstants.keys.diagnosisRelease) != '') {
      let diagnosisRelease = this.findDynamicValueFromKey(
        header as CalculateKeyValGeneric[],
        expertToolConstants.keys.diagnosisRelease,
      ) as string;
     if (diagnosisRelease.split('_').length > 1 && diagnosisRelease.includes('_')){
      flashForm.xentryReleases = diagnosisRelease.split('_')[0] + '.' + diagnosisRelease.split('_')[1] +'.000'
     }
    } else {
      flashForm.xentryReleases = this.findDynamicValueFromKey(
        header as CalculateKeyValGeneric[],
        expertToolConstants.keys.flashData,
      ) as string;
    }
    flashForm.abfragen = data.payload.vedocRequest.doRequest;
    const flashFileExtension = this.findDynamicValueFromKey(
      header as CalculateKeyValGeneric[],
      expertToolConstants.keys.flashFileExtension,
    ) as string;

    flashForm.fileExt =
      flashFileExtension !== ExtConstants.DEFAULT &&
      flashFileExtension !== '' &&
      flashFileExtension !== undefined
        ? (flashForm.fileExt =
            '.' +
            flashFileExtension.substring(
              0,
              flashFileExtension.length,
            )).toUpperCase()
        : ExtConstants.DEFAULT;
    this.storageService.saveData(sessionStorageKeys.flashForm, flashForm);
  }

  createVedocDataFromResponse(
    convertedJsonData: ExpertToolCalculation,
    mappedVedocData: VedocData,
  ) {
    if (!this.checkVinFinInFile(mappedVedocData)) {
      return;
    }

    mappedVedocData?.controlUnits.forEach((controlUnit) => {
      controlUnit.swSnrs = this.getJoinAndSplit(controlUnit.swSnrs, ' ', ',');
    });
    const selected = mappedVedocData.controlUnits.filter((rec) => rec.selected);

    this.storageService.saveData(sessionStorageKeys.selected, selected);
    this.updateFlashForm(convertedJsonData);
  }

  mapVedocDataFromCalculationResponse(
    convertedJsonData: ExpertToolCalculation,
  ): VedocData {
    const ecus = convertedJsonData?.payload.finLog.ecus;
    const controlUnits = this.updateEcus(ecus) as ControlUnits[];

    const etn = this.findDynamicValueFromKey(
      convertedJsonData?.payload.finLog.metadata,
      expertToolConstants.keys.etn,
    ) as CalculateKeyValGeneric[];

    const header = this.findDynamicValueFromKey(
      convertedJsonData?.payload.finLog.metadata,
      expertToolConstants.keys.header,
    ) as CalculateKeyValGeneric[];

    const attributes = this.findDynamicValueFromKey(
      convertedJsonData?.payload.finLog.metadata,
      expertToolConstants.keys.attrib,
    ) as CalculateKeyValGeneric[];

    const vedocData = this.updateFinlogMetaData(etn, header, attributes);
    vedocData.controlUnits = controlUnits;
    return vedocData;
  }

  private generateKeyValue(list) {
    if (Array.isArray(list)) {
      return list;
    }
    return Object.entries(list).map(([key, value]) => {
      return this.generateGenericKeyVal(key, value as string | string[]);
    });
  }

  private generateGenericKeyVal(
    key: string,
    value:
      | string
      | string[]
      | number
      | CalculateKeyValGeneric
      | CalculateKeyValGeneric[],
  ): CalculateKeyValGeneric {
    return new CalculateKeyValGeneric(key, value);
  }

  private populateFinlogHeader(headerList): CalculateKeyValGeneric {
    const headers: CalculateKeyValGeneric[] = this.generateKeyValue(headerList);
    return this.generateGenericKeyVal(expertToolConstants.keys.header, headers);
  }

  private setFinlogAttrib(attributes: CalculateKeyValGeneric[]) {
    const excludedList = ExceludedVedocAttrib;
    if (attributes) {
      return [
        attributes
          .filter((attribute) => !excludedList.includes(attribute.key))
          .map((attribute) => `${attribute.key}=${attribute.value}`)
          .join('\n'),
      ];
    } else return '';
  }

  private populateFinlogAttrib(attributes: string) {
    const attributesObj = this.convertToObject(attributes.split('\n'), '=');
    const attribs: CalculateKeyValGeneric[] =
      attributes === '' ? [] : this.generateKeyValue(attributesObj);
    return this.generateGenericKeyVal(expertToolConstants.keys.attrib, attribs);
  }

  private getControlUnitAttributes(module: string): string {
    switch (module) {
      case expertToolConstants.values.byPriority:
        return this.translocoService.translate(AttributesVpRate.BY_PROIRITY);
      case expertToolConstants.values.always:
        return this.translocoService.translate(AttributesVpRate.ALWAYS);
      case expertToolConstants.values.allModules:
        return this.translocoService.translate(AttributesVpRate.ALL_MODULES);
      case expertToolConstants.values.none:
        return this.translocoService.translate(AttributesVpRate.NONE);
      default:
        return module;
    }
  }

  private populateFinlogEcu(
    selectedList: ControlUnits[],
    response: ExpertToolCalculation,
  ): CalculateKeyValGeneric[][] | CalculateKeyValGeneric[] {
    let ecuFromResponse: CalculateKeyValGeneric[] | CalculateKeyValGeneric[][] =
      [];
    if (response) {
      ecuFromResponse = response.payload.finLog.ecus;
    }

    const ecuMap = selectedList.map((ctu) => {
      let selectedEcu:
        | CalculateKeyValGeneric
        | undefined
        | CalculateKeyValGeneric[];
      if (ecuFromResponse.length) {
        selectedEcu = (ecuFromResponse as CalculateKeyValGeneric[][]).find(
          (ecuKeys: CalculateKeyValGeneric[]) =>
            ecuKeys.find(
              (ecu: CalculateKeyValGeneric) =>
                ecu.key === expertToolConstants.keys.sgDiogname &&
                ecu.value === ctu.diogName,
            ),
        );
      }
      const ecu = {};
      ecu[expertToolConstants.keys.sgDiogname] = ctu.diogName;
      ecu[expertToolConstants.keys.sgRefnr] = ctu.hwSnr;
      ecu[expertToolConstants.keys.vpRateModules] = ctu.attributes
        ? ctu.attributes
        : expertToolConstants.values.byPriority;
      ecu[expertToolConstants.keys.fwmbs] = ctu.swSnrs
        ? ctu.swSnrs.split(' ').join()
        : '';
      ecu[expertToolConstants.keys.sgCurrentSw] = ctu.swSnrs
        .split(' ')
        .join('|');

      //  Let  the value updated in the control unit table when re-calculating again without using the reference selectedEcu
      return this.generateKeyValue(ecu);
    });

    return ecuMap as CalculateKeyValGeneric[][];
  }

  private populateFinlogEtn(etnList): CalculateKeyValGeneric {
    const etn: CalculateKeyValGeneric[] = this.generateKeyValue(etnList);
    return this.generateGenericKeyVal(expertToolConstants.keys.etn, etn);
  }

  private convertToObject(data: string[], separator: string) {
    const obj = {};
    data.map((dt) => {
      const sepArr = dt.split(separator);
      obj[sepArr[0]] = sepArr[1];
      return obj;
    });
    return obj;
  }

  private checkVinFinInFile(mappedVedocData: VedocData): boolean {
    if (!mappedVedocData?.vin && !mappedVedocData?.fin) {
      this.notificationService.showError(
        '',
        this.translocoService.translate(
          'modules.expert-tool.wrong-file-content',
        ),
      );
      return false;
    }
    return true;
  }
}
