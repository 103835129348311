<div class="modal-header">
  <span *ngIf="showDiv.current || showDiv.last" class="modal-title">
    {{
      "modules.data-management.serien-daten.modal.bdp-multi-activation-main-title"
        | transloco
    }}
  </span>

  <span *ngIf="showDiv.next" class="modal-title">
    {{
      "modules.data-management.serien-daten.modal.bdp-multi-activation-select-title"
        | transloco
    }}
  </span>
</div>

<div *ngIf="showDiv.current" class="modal-sub-text">
  {{
    "modules.data-management.serien-daten.modal.bdp-multi-activation-main-sub-title"
      | transloco
  }}
</div>

<div *ngIf="showDiv.next" class="modal-sub-text">
  {{
    "modules.data-management.serien-daten.modal.bdp-multi-activation-select-sub-title"
      | transloco
  }}
</div>

<div *ngIf="showDiv.last" class="modal-sub-text">
  {{
    "modules.data-management.serien-daten.modal.bdp-mutli-activation-activate-sub-title"
      | transloco
  }}
</div>
<div class="modal-body">
  <!-- main table -->
  <div *ngIf="showDiv.current">
    <div class="row">
      <div class="col pull-left custom">
        {{ sdpList?.length }}
        {{
          "modules.data-management.sdp-multi-activation-selected-series"
            | transloco
        }}
      </div>
      <div class="col pull-right">
        <span 
          title="{{
            'modules.data-management.serien-daten.modal.bdp-mutli-activation-activate-add'
              | transloco
          }}"
          (click)="addNewSdpAction()"
          class="main-icon"
        >
          <i class="bi bi-plus"></i>
        </span>
      </div>

      <div class="col-12">
        <div class="vus-container">
          <ngx-datatable
            #dataTable
            class="material datatable-scrollable"
            [columnMode]="'force'"
            [headerHeight]="56"
            rowHeight="auto"
            [scrollbarH]="false"
            [scrollbarV]="false"
            [rows]="sdpList"
            [sorts]="sortConfig"
            [messages]="{ emptyMessage: emptyMessage | transloco }"
          >
            <ngx-datatable-column
              name="{{ 'global.series' | transloco }}"
              prop="baureihe"
              [width]="70"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
              >
                <span *ngIf="value">{{ value }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort"
                  ngbTooltip="{{
                    'modules.data-management.table-header.series'
                      | transloco : { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'global.sd-packet' | transloco }}"
              prop="name"
              [width]="175"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                let-rowIndex="rowIndex"
                ngx-datatable-cell-template
              >
                <span
                  *ngIf="value"
                  placement="bottom"
                  ngbTooltip="{{
                    'modules.data-management.tooltip.sd-packet'
                      | transloco : { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  tooltipClass="note-tooltip"
                  >{{ value }}</span
                >
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort datatable-header-cell-label"
                  ngbTooltip="{{
                    'modules.data-management.table-header.sd-packet'
                      | transloco : { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'global.uploaddate' | transloco }}"
              prop="createdAt"
              [width]="120"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
              >
                <span *ngIf="value">{{ value | date : "dd.MM.yy HH:mm:ss" }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort"
                  ngbTooltip="{{
                    'modules.data-management.table-header.upload-date'
                      | transloco : { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [cellClass]="'text-right'"
              name="System / User"
              prop="username"
              [width]="110"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
              >
                <span *ngIf="value">{{ value }}</span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort"
                  ngbTooltip="{{
                    'modules.data-management.table-header.system'
                      | transloco : { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              name="{{ 'modules.data-management.table-header.status' | transloco }}"
              prop="status"
              [width]="140"
              [resizeable]="false"
            >
              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                let-sort="sortFn"
                ngx-datatable-header-template
              >
                <span
                  class="header-sort"
                  ngbTooltip="{{
                    'modules.data-management.table-header.status'
                      | transloco : { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  (click)="sort()"
                  >{{ column.name }}</span
                >
              </ng-template>

              <ng-template
                let-value="value"
                let-row="row"
                ngx-datatable-cell-template
              >
                <div class="status-box">
                  <div>
                    <span
                      [ngStyle]="{'color': getColor(value)}"
                    >
                      <i class="bi bi-circle-fill"></i>
                    </span>
                  </div>
                  <div class="status-text" *ngIf="value">
                    {{ getValue(value) }}
                  </div>
                </div>
              </ng-template>
            </ngx-datatable-column>

            <ngx-datatable-column
              [cellClass]="'text-center'"
              name="{{ 'global.actions' | transloco }}"
              [flexGrow]="3"
              [sortable]="false"
              [resizeable]="false"
            >
              <ng-template
                let-rowIndex="rowIndex"
                let-row="row"
                let-value="value"
                ngx-datatable-cell-template
              >
                <span 
                  title="{{
                    'modules.expert-tool.tooltip.delete-row' | transloco
                  }}"
                  (click)="deleteData(row)"
                >
                  <i class="bi bi-trash"></i>
                </span>
              </ng-template>

              <ng-template
                let-value="value"
                let-column="column"
                let-rowIndex="rowIndex"
                ngx-datatable-header-template
              >
                <span
                  ngbTooltip="{{
                    'global.actions' | transloco : { value: value }
                  }}"
                  data-container="body"
                  container="body"
                  placement="bottom"
                  tooltipClass="note-tooltip"
                  >{{ column.name }}</span
                >
              </ng-template>
            </ngx-datatable-column>
          </ngx-datatable>
        </div>
      </div>
    </div>
  </div>

  <!-- 2nd div spd selection  -->
  <div *ngIf="showDiv.next">
    <app-fcsc-filter-bdp-inserie-select
      (refreshVisibleTable)="setFilter($event)"
      [data]="sdpList"
      [selectFilter]="true"
    >
    </app-fcsc-filter-bdp-inserie-select>
    <p></p>
    <app-fcsc-bdp-inserie-select
      #inserie
      [baureihe]="baureihe"
      [loadFlag]="true"
      (selectedPacket)="packetSelectedEvent($event)"
    >
    </app-fcsc-bdp-inserie-select>
    <p
      class="notification-error"
      *ngIf="
        existingSdpList.length &&
        inserie &&
        inserie.steuerDaten &&
        inserie.steuerDaten.length
      "
    >
      <app-upload-duration
        [isError]="true"
        [translationKey]="
          'modules.data-management.serien-daten.modal.bdp-multi-activation-select-error'
        "
      ></app-upload-duration>
    </p>
  </div>

  <!-- 3rd div html  -->

  <div *ngIf="showDiv.last">
    <form [formGroup]="serieActivationForm">
      <p></p>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <div class="form-group center">
              <p class="m-0" *ngFor="let sdp of sdpList">
                <span>{{ sdp.name }}</span>
              </p>
            </div>
            <p></p>
            <p></p>
            <div class="form-group center">
              {{
                "modules.data-management.serien-daten.modal.bdp-activation-reason-title"
                  | transloco
              }}
            </div>
            <p></p>
            <div class="custom-rad">
              <div class="col-9">
                <div class="form-group custom-radio">
                  <label
                    class="radio-inline checkradio-container custom-radio-lb"
                  >
                    <input
                      type="radio"
                      value="DATA_UPDATE"
                      formControlName="reason"
                    />
                    <span class="radiomark"></span>
                    <span class="custom-radio-span">
                      {{ updateReason | transloco }}
                    </span>
                  </label>

                  <label
                    class="radio-inline checkradio-container custom-radio-lb"
                  >
                    <input
                      type="radio"
                      value="FAULTY_BDP"
                      formControlName="reason"
                    />
                    <span class="radiomark"></span>
                    <span class="custom-radio-span">
                      {{ wrongBdpReason | transloco }}
                    </span>
                  </label>

                  <label
                    class="radio-inline checkradio-container custom-radio-lb"
                  >
                    <input
                      type="radio"
                      value="OTHER"
                      formControlName="reason"
                    />
                    <span class="radiomark"></span>
                    <span class="custom-radio-span">
                      {{ otherBdpReason | transloco }}</span
                    >
                  </label>
                </div>
              </div>
            </div>

            <p></p>
            <div class="col-12 form-group">
              <label for="note">{{
                "modules.data-management.serien-daten.modal.bdp-activation-comment"
                  | transloco
              }}</label>
              <textarea
                class="form-control"
                id="note"
                formControlName="note"
                rows="3"
              ></textarea>
            </div>

            <div class="mt-3">
              <app-upload-duration
                [estimatedTime]="10"
                [translationKey]="notificationTitle"
              ></app-upload-duration>
            </div>

            <div class="mt-3">
              <app-upload-duration
                [estimatedTime]="10"
                [translationKey]="
                  'modules.data-management.sdp-multi-activation-notification'
                "
              ></app-upload-duration>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<!-- footer  -->

<div class="modal-footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="button-area float-start">
          <button
            *ngIf="showDiv.next"
            type="button"
            class="btn btn-secondary back"
            (click)="backToFirstView()"
          >
            {{ "global.back" | transloco }}
          </button>

          <button
            *ngIf="showDiv.last"
            type="button"
            class="btn btn-secondary back"
            (click)="backToSecondView()"
          >
            {{ "global.back" | transloco }}
          </button>
        </div>

        <div class="button-area float-end">
          <div class="displayed">
            <button
              type="button"
              class="btn btn-secondary back"
              (click)="cancelAction()"
            >
              {{ "global.cancel" | transloco }}
            </button>

            <button
              *ngIf="showDiv.current"
              type="button"
              class="btn btn btn-primary"
              (click)="showActivationFormAction()"
              [disabled]="!sdpList.length"
            >
              {{
                "modules.data-management.serien-daten.modal.bdp-activation-btn"
                  | transloco
              }}
            </button>

            <button
              *ngIf="showDiv.next"
              type="button"
              class="btn btn btn-primary"
              (click)="insertSdpToMainView()"
              [disabled]="
                !(inserie && inserie.steuerDaten && inserie.steuerDaten.length)
              "
            >
              {{
                "modules.data-management.serien-daten.modal.bdp-multi-activation-select-btn"
                  | transloco
              }}
            </button>

            <button
              *ngIf="showDiv.last"
              type="button"
              class="btn btn btn-primary"
              (click)="activateMultipleInSerieAction()"
            >
              {{
                "modules.data-management.serien-daten.modal.bdp-activation-btn"
                  | transloco
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
