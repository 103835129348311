<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"></app-loading-spinner>
<div class="col-10">
  <div class="top-section">
    <div class="item space">
      <app-test-result
        [recentTestRun]="recentTestRun"></app-test-result>
    </div>
    <div class="item">
      <app-test-start
        [testIsRunning]="testIsRunning"></app-test-start>
    </div>
  </div>
  <app-test-cancel
    *ngIf="testIsRunning"
    [testIsRunning]="testIsRunning"
    [activeTestId]="activeTestId"></app-test-cancel>
  <div [ngClass]=" testIsRunning ? '': 'topspace'">
    <app-test-history
      [testRuns]="testRuns"></app-test-history>
  </div>
</div>
