import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ForbiddenPageComponent } from '@core/forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from '@core/not-found-page/not-found-page.component';
import { entitlements } from '@env/entitlements';
import { paths } from '@env/paths';
import { FcscAdminCodeListComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-code-list/fcsc-admin-code-list.component';
import { FcscAdminSectionComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-section.component';
import { FcscAdminVp5VersionsComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-vp5-versions/fcsc-admin-vp5-versions.component';
import { FcscSeriesConfigurationComponent } from '@fcsc/fcsc-admin-section/fcsc-series-configuration/fcsc-series-configuration.component';
import { FcscExperttoolComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-experttool.component';
import { FcscFwErmittlungComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-ermittlung/fcsc-fw-ermittlung.component';
import { FcscFwLogHistoryComponent } from '@fcsc/fcsc-expertool/fcsc-experttool/fcsc-fw-log-history/fcsc-fw-log-history.component';
import { FcscSerienBdpComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-bdp/fcsc-serien-bdp.component';
import { FcscSerienUploadListComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/components/fcsc-serien-upload-list/fcsc-serien-upload-list.component';
import { FcscSerienImagesComponent } from '@fcsc/fcsc-uploadtool/fcsc-serien-images/fcsc-serien-images.component';
import { FcscVp5TestDataComponent } from '@fcsc/fcsc-uploadtool/fcsc-upload-test/components/fcsc-vp5-test-data/fcsc-vp5-test-data.component';
import { FcscUploadVorabComponent } from '@fcsc/fcsc-uploadtool/fcsc-upload-vorab/fcsc-upload-vorab.component';
import { FcscUploadtoolComponent } from '@fcsc/fcsc-uploadtool/fcsc-uploadtool.component';
import {FcscAdminNotificationManagementComponent} from "@fcsc/fcsc-admin-section/fcsc-admin-notification-management/fcsc-admin-notification-management.component";

import { AppAuthGuard } from './app.authguard';
import { FcscAdminFsfLogsComponent } from '@fcsc/fcsc-admin-section/fcsc-admin-fsf-logs/fcsc-admin-fsf-logs.component';
import { FcscAdminTestautomationComponent } from "@fcsc/fcsc-admin-section/fcsc-admin-testautomation/fcsc-admin-testautomation.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: `${paths.fcsc.experttool.root}/${paths.fcsc.experttool.flashware}`,
    // `${paths.fcsc.uploadtool.root}/${paths.fcsc.uploadtool.vorab}`,
  },

  {
    path: `${paths.fcsc.uploadtool.root}`,
    pathMatch: 'full',
    redirectTo: `${paths.fcsc.uploadtool.root}/${paths.fcsc.uploadtool.vorab}`,
  },

  {
    path: `${paths.fcsc.experttool.root}`,
    pathMatch: 'full',
    redirectTo: `${paths.fcsc.experttool.root}/${paths.fcsc.experttool.flashware}`,
  },

  {
    path: `${paths.fcsc.admin.root}`,
    pathMatch: 'full',
    redirectTo: `${paths.fcsc.admin.root}/${paths.fcsc.admin.seriesConfig}`,
  },

  {
    path: paths.fcsc.uploadtool.root,
    component: FcscUploadtoolComponent,

    canActivate: [AppAuthGuard],
    data: {
      requiredEntitlements: [entitlements.VUS.FCSC_VORAB_DATA],
    },

    children: [
      {
        path: paths.fcsc.uploadtool.test,
        redirectTo: paths.fcsc.uploadtool.vp5Test,
      },
      {
        path: paths.fcsc.uploadtool.vp5Test,
        component: FcscVp5TestDataComponent,
        canActivate: [AppAuthGuard],
        data: {
          requiredEntitlements: [entitlements.VUS.FCSC_VP5_TESTING],
        },
      },

      {
        path: paths.fcsc.uploadtool.vorab,
        component: FcscUploadVorabComponent,

        data: {
          oneOfThem: [entitlements.VUS.FCSC_VORAB_DATA],
        },
      },
      {
        path: paths.fcsc.uploadtool.serien,
        component: FcscSerienImagesComponent,

        data: {
          oneOfThem: [
            entitlements.VUS.FCSC_VORAB_DATA,
            entitlements.VUS.FCSC_SERIEN_DATA,
          ],
        },
      },

      {
        path: paths.fcsc.uploadtool.SerienBDP,
        component: FcscSerienBdpComponent,

        data: {
          oneOfThem: [
            entitlements.VUS.FCSC_VORAB_DATA,
            entitlements.VUS.FCSC_SERIEN_DATA,
          ],
        },
      },

      {
        path: paths.fcsc.uploadtool.SerienUploadList,
        component: FcscSerienUploadListComponent,
        canActivate: [AppAuthGuard],
        data: {
          requiredEntitlements: [entitlements.VUS.FCSC_UPLOAD_LIST],
        },
      },
    ],
  },

  {
    path: paths.fcsc.experttool.root,
    component: FcscExperttoolComponent,
    canActivate: [AppAuthGuard],
    data: {
      requiredEntitlements: [entitlements.VUS.FLASHWARE_CALCULATION],
    },
    children: [
      {
        path: paths.fcsc.experttool.flashware,
        component: FcscFwErmittlungComponent,
      },
      {
        path: paths.fcsc.experttool.logHistory,
        component: FcscFwLogHistoryComponent,
        canActivate: [AppAuthGuard],
        data: {
          requiredEntitlements: [
            entitlements.VUS.FCSC_FLASHWARE_CALCULATION_EXPORT,
          ],
        },
      },
    ],
  },

  {
    path: paths.fcsc.admin.root,
    component: FcscAdminSectionComponent,
    canActivate: [AppAuthGuard],
    data: {
      requiredEntitlements: [
        entitlements.VUS.FCSC_FLASHWARE_CALCULATION_CONFIGURATION,
      ],
    },
    children: [
      {
        path: paths.fcsc.admin.seriesConfig,
        component: FcscSeriesConfigurationComponent,

        data: {
          oneOfThem: [
            entitlements.VUS.FCSC_FLASHWARE_CALCULATION_CONFIGURATION,
          ],
        },
      },
      {
        path: paths.fcsc.admin.codeList,
        component: FcscAdminCodeListComponent,

        data: {
          oneOfThem: [
            entitlements.VUS.FCSC_FLASHWARE_CALCULATION_CONFIGURATION,
          ],
        },
      },
      {
        path: paths.fcsc.admin.notificationManagement,
        component: FcscAdminNotificationManagementComponent,

        data: {
          oneOfThem: [
            entitlements.VUS.FCSC_FLASHWARE_CALCULATION_CONFIGURATION,
          ],
        },
      },
      {
        path: paths.fcsc.admin.fsfLogs,
        component: FcscAdminFsfLogsComponent,

        data: {
          requiredEntitlements: [entitlements.VUS.FCSC_FSFLOGS]
        },
      },
      {
        path: paths.fcsc.admin.vpVersions,
        component: FcscAdminVp5VersionsComponent,
        canActivate: [AppAuthGuard],

        data: {
          requiredEntitlements: [entitlements.VUS.FCSC_VP5_VERSION],
        },
      },
      {
        path: paths.fcsc.admin.testautomation,
        component: FcscAdminTestautomationComponent,
        canActivate: [AppAuthGuard],

        data:{
          requiredEntitlements: [entitlements.VUS.FCSC_TESTINGBUTTON]
        }
      }
    ],
  },

  {
    path: `${paths.common.notfound}`,
    component: NotFoundPageComponent,
  },

  {
    path: `${paths.common.forbidden}`,
    component: ForbiddenPageComponent,
  },

  {
    path: '**',
    redirectTo: `${paths.common.notfound}`,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
