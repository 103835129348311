<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"></app-loading-spinner>
<div class="outer-container stretch">
  <span class="start-title">
     {{ "modules.admin-section.testautomation.test-start.title"  | transloco }}
  </span>
  <p class="subtitle">
    {{ "modules.admin-section.testautomation.test-start.subtitle"  | transloco }}
  </p>
  <button *ngIf="testIsRunning" [disabled]="true" class="btn btn-primary justify disabled">
    {{ "modules.admin-section.testautomation.test-start.start"  | transloco }}
  </button>
  <button *ngIf="!testIsRunning" (click)="startTest()" class="btn btn-primary justify">
    {{ "modules.admin-section.testautomation.test-start.start"  | transloco }}
  </button>
</div>
