<app-loading-spinner
  *ngIf="isLoading"
  [loadingTitle]="loadingTitle"
  [loadingSubtitle]="loadingSubtitle"
  [overlay]="true"
></app-loading-spinner>
<div *appRequiredEntitlements="tableListEntitlement" class="outer-container">
  <app-fcsc-filter-vorab
    #filterComponent
    (refreshVisibleTable)="setFilter($event)"
    [data]="steuerDaten"
  >
  </app-fcsc-filter-vorab>


  <form class="mt-3" [formGroup]="form" >
    <div class="col">
      <div class="form-group">
        <label class="checkbox-container">
          <input type="checkbox"
          formControlName="selectedOriginalSdps"
          (change)="handleSdpsCheckChange()" />
          <span class="checkmark"></span>
          <span class="lb-alignment">{{
            "modules.data-management.filter.check-box" | transloco
          }}</span>
        </label>
      </div>
    </div>
  </form>

  <div class="minimum-height"></div>

  <div class="row">
    <div class="col-12 round-button-container">

      <span 
        *appRequiredEntitlements="importEntitlement"
        title="{{
          'modules.data-management.tooltip.upload' | transloco
        }}"
        (click)="uploadData()"
        class="main-icon"
      >
        <i class="bi bi-upload"></i>
      </span>
      
      <span
        *appRequiredEntitlements="deleteEntitlement"
        [ngClass]="{'no-drop-cursor': !selected.length}"
        title="{{
          (!sdpNameList.length
            ? 'modules.data-management.tooltip.multi-delete-disabled'
            : 'modules.data-management.tooltip.multi-delete'
          ) | transloco
        }}"
        class="main-icon"
      >
        <span
          [ngClass]="{'disabled': !selected.length}" 
          (click)="deleteMultipleData()"
        >
          <i class="bi bi-trash-fill"></i>
        </span>
      </span>
    </div>

    <div class="col-12">
      <div class="vus-container">
        <ngx-datatable
          #dataTable
          class="material datatable-scrollable"
          [columnMode]="'flex'"
          [headerHeight]="56"
          rowHeight="auto"
          [scrollbarH]="false"
          [scrollbarV]="false"
          [rows]="steuerDaten"
          [selected]="selected"
          [selectionType]="dataTableSelection"
          [selectAllRowsOnPage]="false"
          (select)="onSelect($event)"
          [footerHeight]="56"
          (page)="setPage($event)"
          (sort)="sortCallback($event)"
          [sorts]="sortConfig"
        >
          <ngx-datatable-column
            [cellClass]="'text-center'"
            name=""
            prop="selected"
            [flexGrow]="0"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            [width]="24"
          >
            <ng-template
              ngx-datatable-cell-template
              let-value="value"
              let-row="row"
              let-isSelected="isSelected"
              let-onCheckboxChangeFn="onCheckboxChangeFn"
            >
              <label class="checkbox-container">
                <input
                  type="checkbox"
                  [checked]="isSelected"
                  [disabled]="isActionDisabled(row)"
                  (change)="onCheckboxChangeFn($event)"
                />
                <span class="checkmark"
                  [ngStyle]="{'cursor': isActionDisabled(row) ? 'no-drop' : 'default'}"
                ></span>
              </label>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.series' | transloco }}"
            prop="baureihe"
            [flexGrow]="3"
            [resizeable]="false"
          >
            <!-- [width]="50" -->
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.table-header.series'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.sd-packet' | transloco }}"
            prop="name"
            [flexGrow]="8"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              let-rowIndex="rowIndex"
              ngx-datatable-cell-template
            >
              <span
                class="sdpaket"
                *ngIf="value"
                (click)="sdPaketsDetails(row, value)"
                placement="bottom"
                ngbTooltip="{{
                  'modules.data-management.tooltip.sd-packet'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                tooltipClass="note-tooltip"
                >{{ value }}</span
              >
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort datatable-header-cell-label"
                ngbTooltip="{{
                  'modules.data-management.table-header.sd-packet'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.uploaddate' | transloco }}"
            prop="createdAt"
            [flexGrow]="6"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value | date : "dd.MM.yy HH:mm:ss" }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.table-header.upload-date'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [cellClass]="'text-right'"
            name="System / User"
            prop="username"
            [flexGrow]="5"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span *ngIf="value">{{ value }}</span>
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.table-header.system'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.comment' | transloco }}"
            prop="note"
            [flexGrow]="7"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <span
                *ngIf="value"
                class="text-pre-wrap"
                placement="bottom"
                [ngbTooltip]="value"
                data-container="body"
                container="body"
                tooltipClass="note-tooltip"
                >{{ truncateNote(value) }}</span
              >
            </ng-template>

            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.table-header.comment'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'modules.data-management.table-header.status' | transloco }}"
            prop="status"
            [flexGrow]="7"
            [resizeable]="false"
          >
            <ng-template
              let-value="value"
              let-column="column"
              let-rowIndex="rowIndex"
              let-sort="sortFn"
              ngx-datatable-header-template
            >
              <span
                class="header-sort"
                ngbTooltip="{{
                  'modules.data-management.table-header.status'
                    | transloco : { value: value }
                }}"
                data-container="body"
                container="body"
                placement="bottom"
                tooltipClass="note-tooltip"
                (click)="sort()"
                >{{ column.name }}</span
              >
            </ng-template>

            <ng-template
              let-value="value"
              let-row="row"
              ngx-datatable-cell-template
            >
              <div class="status-box">
                <div
                  class="spinner-dt-box"
                  *ngIf="isStatusSpinnerLoaded(value)"
                >
                  <span>
                    <i class="bi bi-circle-half"></i>
                  </span>
                </div>

                <div *ngIf="!isStatusSpinnerLoaded(value) && !isStatusInitialized(value)">
                  <span
                    [ngStyle]="{'color': getColor(value)}"
                  >
                    <i class="bi bi-circle-fill"></i>
                  </span>
                </div>
                <div *ngIf="isStatusInitialized(value)">
                  <span
                    [ngStyle]="{'color': getColor(value)}"
                  >
                    <i class="bi bi-circle"></i>
                  </span>
                </div>
                <div class="status-text" *ngIf="value">
                  <!-- tooltip for smoke test -->
                  <span
                    class=""
                    *ngIf="row.info"
                    placement="bottom"
                    ngbTooltip="{{ row.info }}"
                    data-container="body"
                    container="body"
                    tooltipClass="note-tooltip"
                    >{{ getValue(value) }}
                  </span>
                  <span class="" *ngIf="!row.info">{{ getValue(value) }} </span>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'global.actions' | transloco }}"
            [flexGrow]="5"
            [sortable]="false"
            [resizeable]="false"
          >
            <ng-template
              let-rowIndex="rowIndex"
              let-row="row"
              let-value="value"
              ngx-datatable-cell-template
            >
              <span 
                *appRequiredEntitlements="zipExportEntitlement"
                title="{{
                  'modules.data-management.tooltip.download' | transloco
                }}"
                (click)="exportSdpFiles(row)"
              >
                <i class="bi bi-download"></i>
              </span>

              <span 
                [ngClass]="{'no-drop-cursor': isActionDisabled(row)}"
                title="{{
                  isActionDisabled(row)
                  ? ('modules.data-management.tooltip.delete-inserie'
                    | transloco)
                  : null
                }}"
              >
                <span
                  [ngClass]="checkDisability(row)"
                  (click)="deleteData(row)"
                >
                  <i class="bi bi-trash"></i>
                </span>
              </span>

              <span 
                *appRequiredEntitlements="zipExportEntitlement"
                [ngStyle]="isSmokeTestFailed(row) ? {'visibility': 'visible'} : {'visibility': 'hidden'}"
                title="{{
                  'modules.expert-tool.result.log.tooltip.download-icon'
                    | transloco
                }}"
                (click)="exportSmokeTestFaildFile(row)"
              >
              <i class="bi bi-file-earmark-arrow-down-fill"></i>
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer *ngIf="true">
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-selectedCount="selectedCount"
              let-offset="offset"
              let-curPage="curPage"
            >
              <div class="col-8">
                <datatable-pager
                  [pagerLeftArrowIcon]="'datatable-icon-left'"
                  [pagerRightArrowIcon]="'datatable-icon-right'"
                  [pagerPreviousIcon]="'datatable-icon-prev'"
                  [pagerNextIcon]="'datatable-icon-skip'"
                  [count]="response?.totalElements || 0"
                  [page]="page.pageNumber"
                  [size]="page.size"
                  (change)="setPage($event)"
                >
                </datatable-pager>
              </div>

              <div class="col-4 page-result-section server-side">
                <span class="float-end">
                  <span class="result-text">{{
                    "components.footer.result-per-page" | transloco
                  }}</span>
                  <span
                    class="result-value"
                    [ngClass]="{ active: page.size === 10 }"
                    (click)="setPageSize(10)"
                    >10</span
                  >|
                  <span
                    class="result-value margin-override"
                    [ngClass]="{ active: page.size === 30 }"
                    (click)="setPageSize(30)"
                    >30</span
                  >|
                  <span
                    class="result-value"
                    [ngClass]="{ active: page.size === 100 }"
                    (click)="setPageSize(100)"
                    >100</span
                  >
                  <span>
                    {{ "components.footer.files" | transloco }}:
                    {{
                      response && (response.totalElements || 0) > 0
                        ? (response?.number || 0) * page.size + 1
                        : 0
                    }}
                    -
                    {{
                      isNaN(
                        (response?.number || 0) * page.size +
                          (response?.numberOfElements || 0)
                      )
                        ? 0
                        : (response?.number || 0) * page.size +
                          (response?.numberOfElements || 0)
                    }}
                  </span>
                  <span>
                    {{ "components.footer.of" | transloco }}
                    {{
                      response?.totalElements ? response?.totalElements : 0
                    }}</span
                  >
                </span>
              </div>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </div>
</div>