<div class="notification-detail-modal vus-modal">
  <form>
    <div class="modal-header close-top-right">
      <div *ngIf="topic">
        <h3 class="modal-title">{{topic.name}} - {{topic.description}}</h3>
        <p class="modal-subtitle">{{'modules.admin-section.sns-notification.modal.subtitle' | transloco}}</p>
      </div>
      <button type="button" class="btn close" aria-label="Close"
              (click)="dismiss()">
        <span
          class="close-icon"
          icon="times"
        >
          <i class="bi bi-x"></i>
        </span>
      </button>
    </div>
    <div class="modal-body">

      <div class="info-notification" *ngIf="showMailInfo">
        <span
          class="fa-triangle-exclamation"
        >
        <i class="bi bi-exclamation-triangle-fill"></i>
        </span>
        {{'modules.admin-section.sns-notification.modal.confirm-subscription-note' | transloco}}
      </div>

      <ng-container>
        <form [formGroup]="form">
          <div class="email-form">
            <div style="flex: 1;">
              <label for="email">{{'modules.admin-section.sns-notification.modal.email-label' | transloco}}</label>
              <input
                type="email"
                autocomplete="off"
                class="form-control"
                id="email"
                placeholder="{{'modules.admin-section.sns-notification.modal.enter-text' | transloco}}"
                formControlName="email"
                [class.is-invalid]="form.invalid && form.get('email')?.touched "
              />
            </div>
            <div class="submit-container">
              <button class="btn btn-primary" (click)="save()">
                <span>{{'modules.admin-section.sns-notification.modal.submit' | transloco}}</span>
              </button>
            </div>
          </div>
          <div *ngIf="form.get('email')?.invalid && form.get('email')?.touched" class="invalid-feedback">
            <div *ngIf="form.get('email')?.errors?.required">{{'modules.admin-section.sns-notification.modal.required' | transloco}}</div>
            <div *ngIf="form.get('email')?.errors?.pattern">{{'modules.admin-section.sns-notification.modal.invalid-email' | transloco}}</div>
          </div>
        </form>
      </ng-container>

      <ngx-datatable #subscriberTable
                     class="material"
                     [columnMode]="'flex'"
                     [rows]="subscribers"
                     [draggable]="false"
                     [headerHeight]="50"
                     [rowHeight]="'auto'"
                     [sorts]="sortConfig">
        <ngx-datatable-column name="{{'modules.admin-section.sns-notification.modal.email' | transloco}}" prop="email" [flexGrow]="2" [resizeable]="false">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span>{{ value }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ngx-datatable-column name="{{'modules.admin-section.sns-notification.modal.status' | transloco}}" prop="status" [flexGrow]="2" [resizeable]="false">
          <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
            <span>{{ 'global.' + topicStatus[value] | transloco }}</span>
          </ng-template>
        </ngx-datatable-column>
        <ng-container>
          <ngx-datatable-column name="{{'modules.admin-section.sns-notification.modal.action' | transloco}}" [flexGrow]="1" [resizeable]="false">
            <ng-template let-row="row"let-rowIndex="rowIndex" let-expanded="expanded" ngx-datatable-cell-template>
              <div class="action-column" *ngIf="topicStatus[row.status] !== topicStatus.PENDINGCONFIRMATION">
                <span 
                  title="{{
                    'modules.admin-section.sns-notification.modal.deletion-tool-tip' | transloco
                  }}"
                  (action)="deleteEntry(row)"
                >
                  <i class="bi bi-trash"></i>
                </span>
              </div>
            </ng-template>
          </ngx-datatable-column>
        </ng-container>
      </ngx-datatable>
    </div>
  </form>
</div>
